import { useEffect } from "react";
import { datadogRum } from "@datadog/browser-rum";

const DataDog404PageView = () => {
  useEffect(() => {
    const pathname = window?.location?.pathname;

    if (pathname?.startsWith?.("/products")) {
      const segments = pathname
        ?.split?.("/")
        ?.filter?.((segment) => segment !== "");

      datadogRum?.setViewContextProperty?.("pdp", {
        slug: segments?.[1],
        nsid: segments?.[2],
        status: "oops"
      });
    }
  }, []);

  return null;
};

export default DataDog404PageView;
