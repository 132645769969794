import React, { useEffect, useState } from "react";
import Metadata from "@components/Metadata";
import GenericPageWrapper from "../wrappers/GenericPageWrapper";
import buildAmpClient from "@lib/buildAmpClient";
import { fetchGlobalContent } from "@utils";
import { Content } from "@components";
import AnalyticsData from "@serenaandlily/components/AnalyticsData";
import DataDog404PageView from "@components/DataDog/DataDog404PageView";

export default function Custom404({ content, globalContent, errors }) {
  const { body, metadata } = content;
  const [errorsLogged, setErrorsLogged] = useState(false);

  useEffect(() => {
    if (errors && typeof errors === "object" && !errorsLogged) {
      // eslint-disable-next-line no-console
      console.error(JSON.stringify(errors));
      setErrorsLogged(true);
    }
  }, [errorsLogged, errors]);

  return (
    <React.Fragment>
      <AnalyticsData id="a-product" data={{}} />
      <DataDog404PageView />
      <Metadata {...metadata} />
      <GenericPageWrapper pageType="404" {...globalContent}>
        {body &&
          body.map((bodyContent, idx) => {
            const key = `homepage-body-content-${idx}`;
            return <Content key={key} {...bodyContent} />;
          })}
      </GenericPageWrapper>
    </React.Fragment>
  );
}

export async function getStaticProps(context) {
  const amplienceClient = await buildAmpClient(context);
  let content = null;
  const response = await amplienceClient.getContentItemByKey("404");
  content = await response.toJSON();
  const globalContent = await fetchGlobalContent(amplienceClient);

  if (globalContent?.footer) {
    content.body.push(globalContent.footer);
    delete globalContent.footer;
  }

  return {
    props: {
      content,
      globalContent: globalContent
    },

    revalidate: Number(process.env.ISR_SECONDS)
  };
}
